import React, { Component } from 'react';
import './App.css';
import {subscribeSubmitions, acceptSubmition, deleteSubmition, createAccount} from './connection';

class Submition extends Component{
    render(){
        return (
            <tr>
                <td>{this.props.obj.course_name}</td>
                <td>{this.props.obj.student_name}</td>
                <td>{this.props.obj.parent_name}</td>
                <td>{this.props.obj.email}</td>
                <td>{this.props.obj.phone}</td>
                {
                    !this.props.obj.checked ?
                    <td>
                        <button className="btn btn-primary" onClick={this.accept.bind(this)}>Accept</button>
                        <button className="btn btn-primary" onClick={this.delete.bind(this)}>Delete</button>
                    </td> :
                    <td>
                        <button className="btn btn-primary" onClick={this.create.bind(this)}>Създай акаунт</button>
                    </td>
                }
            </tr>
        );
    }
    accept(){
        acceptSubmition(this.props.obj.id);
    }
    delete(){
        deleteSubmition(this.props.obj.id);
    }
    create(){
        createAccount(this.props.obj);
    }
}

class Submitions extends Component{
    constructor(props){
        super(props);
        this.state = {submitions:[]}
        subscribeSubmitions(
            (submitions)=>{
                this.setState({
                    submitions:submitions
                })
            },
            (submition)=>{
                this.setState({
                    submitions:this.state.submitions.concat([submition])
                })
            }
        )
    }
    render(){
        return (
            <div>
                <h3>Необработени</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Курс</th>
                            <th>Ученик</th>
                            <th>Родител</th>
                            <th>email</th>
                            <th>телефон</th>
                            <th>control</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.submitions.filter((x)=>{return !x.checked;}).map((x, ind) => {
                                return <Submition key={ind} obj={x}/>;
                            })
                        }
                    </tbody>
                </table>
                <h3>Приети</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Курс</th>
                            <th>Ученик</th>
                            <th>Родител</th>
                            <th>email</th>
                            <th>телефон</th>
                            <th>control</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.submitions.filter((x)=>{return x.checked;}).map((x, ind) => {
                                return <Submition key={ind} obj={x}/>;
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export {Submitions};