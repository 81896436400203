import openSocket from 'socket.io-client';

const socket = openSocket();

function loginAdmin(user, pass, cb){
    socket.emit("login", user, pass);
    socket.on("sc", (id)=>{
        cb(id);
    })
}

function subscribeYears(cb){
    socket.emit("gmy");//give me years
    socket.on("hgy", (years) => {
        cb(years);
    });//here you go years
}

function subscribeSubmitions(cb, cb_add){
    socket.on("hgs", (submitions)=>{
        cb(submitions);
    });
    socket.on("adds", (submition)=>{
        cb_add(submition);
    });
    socket.emit("gms");
}

function updateYear(id, title, year, visible){
    if (id!==-1) socket.emit("upy", id, title, year, visible);
    else socket.emit("addy", title, year, visible);
}

function updateCourseType(id, year_id, name, title, description, instructions){
    if (id!==-1) socket.emit("upct", id, name, title, description, instructions);
    else socket.emit("addct", year_id, name, title, description, instructions);
}

function updateCourse(id, type_id, name, times, total, begin_info, teachers){
    if (id!==-1) socket.emit("upc", id, name, times, total, begin_info, teachers);
    else socket.emit("addc", type_id, name, times, total, begin_info, teachers);
}

function removeYear(id){
    socket.emit("dely", id);
}

function removeCourseType(id){
    socket.emit("delct", id);
}

function removeCourse(id){
    socket.emit("delc", id);
}

function subscribeToAccounts(course_id, cb){
    socket.emit("acc", course_id);
    if (course_id === -1) socket.on("hga", (accounts) => {
        cb(accounts);
    })
    else socket.on("acc"+course_id, (accounts)=>{
        cb(accounts);
    })
}

function updateStudent(id, course_id, b_date, e_date){
    socket.emit("updsc", id, course_id, b_date, e_date);
}

function forgotPassword(id, username, name){
    socket.emit("fgps", id, username, name);
}

function subscribeAlert(cb){
    socket.on("alert", (txt)=>{cb(txt)});
}

function acceptSubmition(id){
    socket.emit("accs", id);
}

function deleteSubmition(id){
    socket.emit('rejs', id);
}

function createAccount(submition){
    socket.emit("createacc", submition);
}

export {loginAdmin, subscribeYears, updateYear, removeYear, removeCourseType, updateCourseType, updateCourse, removeCourse, subscribeToAccounts, updateStudent, forgotPassword, subscribeSubmitions, subscribeAlert, acceptSubmition, deleteSubmition, createAccount};