import React, { Component } from 'react';
import {YearsList} from './courseLists';
import './App.css';

class Courses extends Component{
    constructor(props){
        super(props);
        this.state = {navigation: []}
    }
    /*
    currentNavPage(){
        if (this.state.navigation.length === 0) return <YearsList/>;
        if (this.state.navigation.length === 1) return <CoursesList/>;
        if (this.state.navigation.length === 2) return <AccountsList/>;
    }
    */
    render(){
        return (
            <div className="container">
                <YearsList/>
            </div>
        );
    }
}

export {Courses};