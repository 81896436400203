import React, { Component } from 'react';
import './App.css';
import { Jumbotron } from 'react-bootstrap'
import { loginAdmin } from './connection';

class LoginForm extends Component{
    login(e){
        e.preventDefault();
        loginAdmin(
            document.getElementById('username').value,
            document.getElementById('password').value,
            (id) => {
                if (id===-1) alert("Wrong username or password");
                else {
                    alert("Logged in as " + id);
                    this.props.successfulLogin(id);
                }
            }
        )
    }
    render(){
        return(
            <Jumbotron>
                <h3>Log in</h3>
                <form onSubmit={this.login.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="username">Email (username)</label>
                        <input type="email" className='form-control' id='username' placeholder="Email" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className='form-control' id='password' required/>
                    </div>
                    <button type="submit" className="btn btn-primary">Log in</button>
                </form>
            </Jumbotron>
        );
    }
}

export{LoginForm}
