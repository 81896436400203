import React, { Component } from 'react';
import './App.css';
import { subscribeToAccounts, forgotPassword } from './connection';

class Account extends Component{
    render(){
        return (
            <tr>
                <td>{this.props.obj.name}</td>
                <td>{this.props.obj.username}</td>
                <td><button className="btn btn-primary" onClick={()=>{forgotPassword(this.props.obj.id, this.props.obj.username, this.props.obj.name)}}>Генерирай нова парола и изпрати по пощата</button></td>
            </tr>
        );
    }
}

class Accounts extends Component{
    constructor(props){
        super(props);
        this.state = {accounts: []};
        subscribeToAccounts(-1, (accounts)=>{
            this.setState({
                accounts: accounts
            });
        })
    }
    render(){
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>name</th>
                        <th>username</th>
                        <th>control</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.accounts.map((obj, ind)=>{
                        return <Account obj={obj} key={ind}/>
                    })}
                </tbody>
            </table>
        );
    }
}

export {Accounts};