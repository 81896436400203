import React, { Component } from 'react';
import './App.css';
import { subscribeYears, updateYear, removeYear, removeCourseType, updateCourseType, updateCourse, removeCourse, subscribeToAccounts, updateStudent } from './connection';
import { HTMLVis } from './htmlvis';
import {Jumbotron} from 'react-bootstrap';

class Account extends Component{
    submit(){
        updateStudent(
            this.props.obj.id,
            this.props.obj.course_id,
            document.getElementById("begin_date"+this.props.obj.id).value,
            document.getElementById("end_date"+this.props.obj.id).value
        );
    }
    render(){
        return (
            <tr>
                <td>{this.props.obj.name}</td>
                <td>{this.props.obj.username}</td>
                <td>{this.props.obj.email}</td>
                <td><input type="text" id={"begin_date"+this.props.obj.id} defaultValue={new Date(this.props.obj.begin_date).toISOString().slice(0, 10)}/></td>
                <td><input type="text" id={"end_date"+this.props.obj.id} defaultValue={new Date(this.props.obj.end_date).toISOString().slice(0, 10)}/></td>
                <td><button className="btn btn-primary" onClick={this.submit.bind(this)}>Save</button></td>
            </tr>
        )
    }
}

class AccountsList extends Component{
    constructor(props){
        super(props);
        this.state = {accounts: []};
        subscribeToAccounts(this.props.courseId, (accounts)=>{
            this.setState({
                accounts: accounts
            });
        })
    }
    render(){
        return (
            <div>
                <button onClick={this.props.onBack} className="btn btn-primary">Обратно</button>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Начална дата</th>
                            <th>Крайна дата</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.accounts.map((obj, ind)=>{
                                return <Account obj={obj} key={ind}/>;
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
};

class CourseForm extends Component{
    submit(e){
        e.preventDefault();
        this.props.onsave(
            this.props.obj.id,
            this.props.obj.type_id,
            document.getElementById("name").value,
            document.getElementById("times").value,
            document.getElementById("total").value,
            document.getElementById("begin_info").value,
            JSON.parse(document.getElementById("teachers").value)
        )
        document.getElementById("name").value = "";
        document.getElementById("times").value = "";
        document.getElementById("total").value = "";
        document.getElementById("begin_info").value = "";
        document.getElementById("teachers").value = "";
        this.props.oncancel();
    }
    render(){
        return (
            <form onSubmit={this.submit.bind(this)}>
                <div className="form-group">
                    <label htmlFor="name">name</label>
                    <input id="name" type="text" defaultValue={this.props.obj.name}/>
                </div>
                <div className="form-group">
                    <label htmlFor="times">times</label>
                    <input id="times" type="text" defaultValue={JSON.stringify(this.props.obj.times)}/>
                </div>
                <div className="form-group">
                    <label htmlFor="total">total</label>
                    <input id="total" type="text" defaultValue={this.props.obj.total}/>
                </div>
                <div className="form-group">
                    <label htmlFor="begin_info">begin_info</label>
                    <input id="begin_info" type="text" defaultValue={this.props.obj.begin_info}/>
                </div>
                <div className="form-group">
                    <label htmlFor="teachers">teachers</label>
                    <input id="teachers" type="text" defaultValue={JSON.stringify(this.props.obj.teachers)}/>
                </div>
                <button type="submit" className="btn btn-primary">Save</button>
                <button type="button" className="btn btn-primary" onClick={this.props.oncancel}>Cancel</button>
            </form>
        );
    }
}

class Course extends Component{
    constructor(props){
        super(props);
        this.state = {edit: 0};
    }
    changeEditState(){
        this.setState({
            edit: !this.state.edit
        });
    }
    render(){
        return this.state.edit ?
            <Jumbotron><CourseForm obj={this.props.obj} onsave={updateCourse} oncancel={this.changeEditState.bind(this)}/></Jumbotron> :
            <Jumbotron>
                <h3>{this.props.obj.name}</h3>
                <p>{JSON.stringify(this.props.obj.times)}</p>
                <p>{JSON.stringify(this.props.obj.teachers)}</p>
                <button className="btn btn-primary" onClick={this.props.onEnter}>Enter</button>
                <button className="btn btn-primary" onClick={this.changeEditState.bind(this)}>Edit</button>
                <button className="btn btn-primary" onClick={()=>{removeCourse(this.props.obj.id)}}>Remove</button>
            </Jumbotron>
    }
}

class CoursesList extends Component{
    constructor(props){
        super(props);
        this.state = {nav: -1};
    }
    navigate(ind){
        this.setState({
            nav: ind
        });
    }
    render(){
        return this.state.nav===-1 ?
            <div>
                <button className="btn btn-primary" onClick={this.props.onBack}>Обратно</button>
                {this.props.obj.courses.map((obj, ind) => {
                    return (<Course obj={obj} key={ind} onEnter={()=>{this.navigate(ind)}}/>);
                })}
                <Jumbotron><CourseForm obj={{id:-1, type_id:this.props.obj.id, name:"", time:"", begin_info:"", teachers:[]}} onsave={updateCourse} oncancel={()=>{this.navigate(-1)}}/></Jumbotron>
            </div> :
            <AccountsList courseId={this.props.obj.courses[this.state.nav].id} onBack={()=>{this.navigate(-1)}}/>
    }
};

class CourseTypeForm extends Component{
    submit(e){
        e.preventDefault();
        this.props.onsave(
            this.props.obj.id,
            this.props.obj.year_id,
            document.getElementById("name").value,
            document.getElementById("title").value,
            document.getElementById("description").value,
            document.getElementById("instructions").value
        )
        document.getElementById("name").value = "";
        document.getElementById("title").value = "";
        document.getElementById("description").value = "";
        document.getElementById("instructions").value = "";
        this.props.oncancel();
    }
    render(){
        return (
            <form onSubmit={this.submit.bind(this)}>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input id="title" type="text" defaultValue={this.props.obj.title}/>
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" defaultValue={this.props.obj.name}/>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea id="description" defaultValue={this.props.obj.description}></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="instructions">Instructions</label>
                    <textarea id="instructions" defaultValue={this.props.obj.instructions}></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Save</button>
                <button type="button" className="btn btn-primary" onClick={this.props.oncancel}>Cancel</button>
            </form>
        );
    }
}

class CourseType extends Component{
    constructor(props){
        super(props);
        this.state = {edit: 0};
    }
    changeEditState(){
        this.setState({
            edit: !this.state.edit
        });
    }
    render(){
        return this.state.edit ?
            <Jumbotron><CourseTypeForm obj={this.props.obj} onsave={updateCourseType} oncancel={this.changeEditState.bind(this)}/></Jumbotron> :
            <Jumbotron>
                <h3>{this.props.obj.title}</h3>
                <p>{this.props.obj.name}</p>
                <HTMLVis html={this.props.obj.description}/>
                <HTMLVis html={this.props.obj.instructions}/>
                <button className="btn btn-primary" onClick={this.props.onEnter}>Enter</button>
                <button className="btn btn-primary" onClick={this.changeEditState.bind(this)}>Edit</button>
                <button className="btn btn-primary" onClick={()=>{removeCourseType(this.props.obj.id)}}>Remove</button>
            </Jumbotron>
    }
}

class CourseTypesList extends Component{
    constructor(props){
        super(props);
        this.state = {nav: -1};
    }
    navigate(ind){
        this.setState({
            nav: ind
        });
    }
    render(){
        return this.state.nav===-1 ?
            <div>
                <button className="btn btn-primary" onClick={this.props.onBack}>Обратно</button>
                {this.props.obj.courseTypes.map((obj, ind) => {
                    return (<CourseType obj={obj} key={ind} onEnter={()=>{this.navigate(ind)}}/>);
                })}
                <Jumbotron><CourseTypeForm obj={{id:-1, year_id:this.props.obj.id, name:"", title:"", description:"", instructions:""}} onsave={updateCourseType} oncancel={()=>{this.navigate(-1)}}/></Jumbotron>
            </div> :
            <CoursesList obj={this.props.obj.courseTypes[this.state.nav]} onBack={()=>{this.navigate(-1)}}/>
    }
};

class YearForm extends Component{
    submit(e){
        e.preventDefault();
        this.props.onsave(
            this.props.obj.id,
            document.getElementById("title").value,
            document.getElementById("year").value,
            document.getElementById("visible").checked
        )
        document.getElementById("title").value = "";
        document.getElementById("year").value = "";
        this.props.oncancel();
    }
    render(){
        return (
            <form onSubmit={this.submit.bind(this)}>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input id="title" type="text" defaultValue={this.props.obj.title}/>
                </div>
                <div className="form-group">
                    <label htmlFor="year">Year</label>
                    <input id="year" type="number" defaultValue={this.props.obj.year}/>
                </div>
                <div className="form-group">
                    <label htmlFor="visible">Visible</label>
                    {
                        this.props.obj.visible ?
                        <input id="visible" type="checkbox" defaultChecked/> :
                        <input id="visible" type="checkbox"/>
                    }
                </div>
                <button type="submit" className="btn btn-primary">Save</button>
                <button type="button" className="btn btn-primary" onClick={this.props.oncancel}>Cancel</button>
            </form>
        );
    }
}

class Year extends Component{
    constructor(props){
        super(props);
        this.state = {edit: false};
    }
    changeEditState(){
        this.setState({edit: !this.state.edit});
    }
    render(){
        return this.state.edit ? 
            <Jumbotron><YearForm obj={this.props.obj} oncancel={this.changeEditState.bind(this)} onsave={updateYear}/></Jumbotron> :
            <Jumbotron style={{opacity: this.props.obj.visible? 1 : 0.5}}>
                <h3>{this.props.obj.title}</h3>
                <p>{this.props.obj.year}</p>
                <button className="btn btn-primary" onClick={this.props.onEnter}>Enter</button>
                <button className="btn btn-primary" onClick={this.changeEditState.bind(this)}>Edit</button>
                <button className="btn btn-primary" onClick={()=>{removeYear(this.props.obj.id)}}>Remove</button>
            </Jumbotron>
    }
}

class YearsList extends Component{
    constructor(props){
        super(props);
        this.state = {years: [], nav: -1};
        subscribeYears((years)=>{
            this.setState({years: years, nav: this.state.nav});
        });
    }
    navigate(ind){
        this.setState({
            years: this.state.years,
            nav: ind
        });
    }
    render(){
        return this.state.nav===-1 ?
            <div>
                {this.state.years.map((obj, ind) => {
                    return (<Year obj={obj} key={ind} onEnter={()=>{this.navigate(ind)}}/>);
                })}
                <Jumbotron><YearForm obj={{id:-1, title:"", year:2020}} oncancel={()=>{}} onsave={updateYear}/></Jumbotron>
            </div> :
            <CourseTypesList obj={this.state.years[this.state.nav]} onBack={()=>{this.navigate(-1)}}/>
    }
};
export {YearsList, CourseTypesList, AccountsList};