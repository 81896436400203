import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './App.css';

class HTMLVis extends Component{
    render(){
        return <div></div>
    }
    componentDidMount(){
        ReactDOM.findDOMNode(this).innerHTML = this.props.html;
    }
}

export { HTMLVis };