import React, { Component } from 'react';
import './App.css';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LoginForm } from './login';
import { Submitions } from './submitions';
import { Accounts } from './accounts';
import { Courses } from './courses';
import {subscribeAlert} from './connection';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {adminId: -1, activeKey: 1};
    subscribeAlert((txt)=>{
      alert(txt);
    })
  }
  changeAdminId(id){
    this.setState({adminId: id, activeKey: this.state.activeKey});
  }
  handleSelect(key){
    this.setState({adminId: this.state.adminId, activeKey: key});
  }
  currentTab(){
    if (this.state.activeKey === 1) return (<Submitions/>);
    if (this.state.activeKey === 2) return (<Accounts/>);
    if (this.state.activeKey === 3) return (<Courses/>);
  }
  render(){
    return this.state.adminId !== -1 ? (
        <div>
          <Navbar inverse collapseOnSelect onSelect={this.handleSelect.bind(this)}>
            <Navbar.Header>
              <Navbar.Brand>
                <a href="#brand">SIcademy</a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav activeKey={this.state.activeKey}>
                <NavItem eventKey={1}>
                  Submitions
                </NavItem>
                <NavItem eventKey={2} href="#">
                  Accounts
                </NavItem>
                <NavItem eventKey={3} href="#">
                  Courses
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="container">
            {
              this.currentTab()
            }
          </div>
        </div>
      ) : (
        <div className="container">
          <LoginForm successfulLogin = {this.changeAdminId.bind(this)}/>
        </div>
      )
  }
}

export default App;
